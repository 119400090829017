import { usePassThrough } from 'primevue/passthrough'
import Tailwind from 'primevue/passthrough/tailwind'
import { directive } from 'vue3-click-away'

// Original settings for Tailwind theme:
// https://github.com/primefaces/primevue/blob/master/components/lib/passthrough/tailwind/index.js

export const TRANSITIONS = {
  toggleable: {
    enterFromClass: 'max-h-0',
    enterActiveClass: 'overflow-hidden transition-all duration-500 ease-in-out',
    enterToClass: 'max-h-40',
    leaveFromClass: 'max-h-40',
    leaveActiveClass: 'overflow-hidden transition-all duration-500 ease-in',
    leaveToClass: 'max-h-0'
  },
  overlay: {
    enterFromClass: 'opacity-0 scale-75',
    enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
    leaveActiveClass: 'transition-opacity duration-150 ease-linear',
    leaveToClass: 'opacity-0'
  }
}

const CustomTailwind = usePassThrough(
  Tailwind,
  {
    directives: {
      tooltip: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        root: ({ context }) => ({
          class: [
            'absolute max-w-sm leading-tight',
            {
              'py-0 px-0': context?.right || context?.left || (!context?.right && !context?.left && !context?.top && !context?.bottom),
              'py-1 px-0': context?.top || context?.bottom
            }
          ]
        }),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        arrow: ({ context }) => ({
          class: [
            'absolute w-0 h-0 border-transparent border-solid',
            {
              '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-gray-600': context?.right || (!context?.right && !context?.left && !context?.top && !context?.bottom),
              '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-gray-600': context?.left,
              '-m-l-2 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-gray-600': context?.top,
              '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-gray-600': context?.bottom
            }
          ]
        }),
        text: {
          class: 'px-3 py-2 bg-gray-600 text-white rounded-md whitespace-pre-line break-words font-normal'
        }
      }
    },
    checkbox: {
      root: {
        class: ['cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6']
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      input: ({ props, context }) => ({
        class: [
          'prime-vue-checkbox',
          {
            '': !context.checked,
            'is-checked': context.checked
          },
          {
            '': !props.disabled,
            'is-disabled': props.disabled
          }
        ]
      }),
      icon: {
        class: 'w-4 h-4 transition-all duration-200 text-white text-base '
      }
    },

    multiselect: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      root: ({ props }) => ({
        class: [
          'multiselect-root',
          { 'opacity-60 select-none pointer-events-none cursor-default': props?.disabled }
        ]
      }),
      labelContainer: {
        class: 'overflow-hidden flex flex-auto cursor-pointer'
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label: ({ props }) => ({
        class: [
          'block overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis',
          'text-gray-700',
          'p-2 transition duration-200',
          {
            '!p-2': props.display !== 'chip' && (props?.modelValue == null || props?.modelValue == undefined),
            '!py-1.5 px-3': props.display == 'chip' && props?.modelValue !== null
          }
        ]
      }),
      token: {
        class: ['py-1 px-2 mr-2 bg-gray-300 text-gray-700 rounded-full', 'cursor-default inline-flex items-center']
      },
      removeTokenIcon: {
        class: 'ml-2'
      },
      trigger: {
        class: ['flex items-center justify-center shrink-0', 'bg-transparent text-gray-600 w-12 rounded-tr-lg rounded-br-lg']
      },
      panel: {
        class: ['bg-white text-gray-700 border border-gray-300 rounded-md shadow']
      },
      header: {
        class: ['multiselect-header p-3 border-b border-gray-300 text-gray-700 bg-gray-100 rounded-t-lg', 'flex items-center justify-between']
      },
      headerCheckboxContainer: {
        class: ['inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6']
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      headerCheckbox: ({ context }) => ({
        class: [
          'header-checkbox',
          {
            'border-gray-300 bg-white': !context?.selected,
            'is-selected': context?.selected
          }
        ]
      }),
      headercheckboxicon: {
        class: 'w-4 h-4 transition-all duration-200 text-white text-base'
      },
      closeButton: {
        class: [
          'multiselect-close-btn flex items-center justify-center overflow-hidden relative',
          'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
          'hover:text-gray-700 hover:border-transparent hover:bg-gray-200 ',
          'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
        ]
      },
      closeButtonIcon: {
        class: 'w-4 h-4 inline-block'
      },
      wrapper: {
        class: ['max-h-[200px] overflow-auto', 'bg-white text-gray-700 border-0 rounded-md shadow-lg', '']
      },
      list: {
        class: 'py-3 list-none m-0'
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      item: ({ context }) => ({
        class: [
          'multiselect-item',
          {
            '': !context.focused && !context.selected,
            'is-focused': context.focused && !context.selected,
            'is-focused is-selected': context.focused && context.selected,
            'is-selected': !context.focused && context.selected
          }
        ]
      }),
      checkboxContainer: {
        class: ['inline-flex cursor-pointer select-none align-bottom relative', 'mr-2', 'w-6 h-6']
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      checkbox: ({ context }) => ({
        class: [
          'multiselect-item-checkbox',
          {
            'is-selected': context?.selected
          }
        ]
      }),
      checkboxicon: {
        class: 'w-4 h-4 transition-all duration-200 text-white text-base'
      },
      itemgroup: {
        class: ['m-0 p-3 text-gray-800 bg-white font-bold', 'cursor-auto']
      },
      filtercontainer: {
        class: 'relative w-full mr-2'
      },
      filterinput: {
        class: [
          'multiselect-filter-input'
        ]
      },
      filtericon: {
        class: '-mt-2 absolute top-1/2'
      },
      clearicon: {
        class: 'text-gray-500 right-12 -mt-2 absolute top-1/2'
      },
      transition: TRANSITIONS.overlay
    },
    autocomplete: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      root: ({ props }) => ({
        class: [
          'relative inline-flex',
          {
            'opacity-60 select-none pointer-events-none cursor-default': props.disabled
          },
          { 'w-full': props.multiple }
        ]
      }),
      container: {
        class: [
          'm-0 list-none cursor-text overflow-hidden flex items-center flex-wrap w-full',
          'px-3 py-2 gap-2',
          'font-sans text-base text-gray-700 bg-white border border-chorus-burnt-orange transition duration-200 ease-in-out appearance-none',
          'focus:outline-offset-0 focus:shadow-red-100 hover:border-chorus-burnt-orange focus:outline-none'
        ]
      },
      inputtoken: {
        class: ['py-0.375rem px-0', 'flex-1 inline-flex']
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      input: ({ props }) => ({
        class: [
          'm-0 w-full',
          ' transition-colors duration-200 appearance-none rounded-sm',
          { 'rounded-tr-none rounded-br-none': props.dropdown },
          {
            'text-base text-gray-700 bg-white p-3 border border-chorus-burnt-orange focus:border-[#FF168E] focus:outline-[#FF168E] focus:outline-8':
                            !props.multiple,
            'text-base text-gray-700 border-0 outline-none bg-transparent m-0 p-0 shadow-none rounded-none w-full': props.multiple
          }
        ]
      }),
      token: {
        class: ['py-1 px-2 mr-2 bg-gray-300 text-gray-700 rounded-full', 'cursor-default inline-flex items-center']
      },
      dropdownbutton: {
        root: {
          class: 'rounded-tl-none rounded-bl-none'
        }
      },
      panel: {
        class: ['bg-white text-gray-700 border-0 rounded-md shadow-lg', 'max-h-[200px] overflow-auto', 'bg-white text-gray-700 border-0 rounded-md shadow-lg']
      },
      list: {
        class: 'py-3 list-none m-0'
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      item: ({ context }) => ({
        class: [
          'cursor-pointer font-normal overflow-hidden relative whitespace-nowrap',
          'm-0 p-3 border-0  transition-shadow duration-200 rounded-none',
          'hover:text-gray-700 hover:bg-gray-200',
          {
            'text-gray-700': !context.focused && !context.selected,
            'bg-gray-300 text-gray-700': context.focused && !context.selected,
            'bg-burnt-orange text-gray-900': context.focused && context.selected,
            'bg-red-50 text-gray-800': !context.focused && context.selected
          }
        ]
      }),
      itemgroup: {
        class: ['m-0 p-3 text-gray-800 bg-white font-bold', 'cursor-auto']
      },
      transition: TRANSITIONS.overlay
    }
  },
  {
    mergeSections: false, // override instead of merge
    mergeProps: false
  }
)

export default CustomTailwind
