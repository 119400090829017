import { type RouteRecordRaw } from 'vue-router'

const basePath = '/fault'
export const FMPRoutes: Array<RouteRecordRaw> = [
  {
    path: basePath,
    name: 'FMP-Home',
    component: () => import('@/pages/FMP/Home.vue'),
    meta: { layout: 'FMP' }
  },
  {
    path: `${basePath}/create-fre`,
    name: 'FMP-CreateFRE',
    component: () => import('@/pages/FMP/CreateFRE.vue'),
    meta: { layout: 'FMP' }
  },
  {
    path: `${basePath}/create-misc-ticket`,
    name: 'FMP-CreateMiscTicket',
    component: () => import('@/pages/FMP/CreateMiscTicket.vue'),
    meta: { layout: 'FMP' }
  },
  {
    path: `${basePath}/search-tickets`,
    name: 'FMP-SearchPage',
    component: () => import('@/pages/FMP/SearchPage.vue'),
    meta: { layout: 'FMP' }
  },
  {
    path: `${basePath}/fre/:id`,
    name: 'FMP-ViewFRE',
    component: () => import('@/pages/FMP/ViewFRE.vue'),
    meta: { layout: 'FMP' }
  },
  {
    path: `${basePath}/misc-ticket/:id`,
    name: 'FMP-ViewMiscTicket',
    component: () => import('@/pages/FMP/ViewMiscTicket.vue'),
    meta: { layout: 'FMP' }
  },
  {
    path: `${basePath}/release-notes`,
    name: 'FMP-ReleaseNotes',
    component: () => import('@/pages/FMP/ReleaseNotes.vue'),
    meta: { layout: 'FMP' }
  },
  {
    path: `${basePath}/login`,
    name: 'Login',
    component: () => import('@/pages/FMP/LoginPage.vue'),
    meta: { layout: 'FMP' }
  }

]
