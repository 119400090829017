import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
// import { activePinia } from '@/stores/FaultManagementPortalStore'
import VueClickAway from 'vue3-click-away'
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import CustomTailwind from './tailwind.primevue.config'

import * as Sentry from '@sentry/vue'
import { ENV } from './settingsFieldwork'

// ============================= MS clarity =============================
if (ENV === 'PROD') {
  const scriptEl = document.createElement('script')
  scriptEl.type = 'text/javascript'
  scriptEl.src = '/ms-clarity.js'
  document.head.appendChild(scriptEl)
}
// =============================/MS clarity =============================

const app = createApp(App)

app.directive('tooltip', Tooltip)

// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', VueDatePicker)
// app.use(activePinia)
app.use(router)
app.use(VueClickAway) // Makes 'v-click-away' directive usable in every component
app.use(PrimeVue, {
  unstyled: true,
  pt: CustomTailwind
})

// Sentry error tracking
if (ENV === 'PROD') {
  Sentry.init({
    Vue: app,
    dsn: 'https://1a07aa481120bedf81b9f79a114a2a34@o117921.ingest.us.sentry.io/4506787844915200',
    integrations: [],
    environment: ENV
  })
}

app.mount('#app')
