import { type RouteRecordRaw } from 'vue-router'

const basePath = '/fieldwork'
export const SMRoutes: Array<RouteRecordRaw> = [
  {
    path: '/schedules',
    name: 'SM-ScheduleManager',
    component: () => import('@/pages/SM/ScheduleManagerHome.vue'),
    meta: {
      title: 'Schedule Manager',
      layout: 'FW',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/tools',
    name: 'SM-SerCoHome',
    component: () => import('@/pages/SM/SerCoHome.vue'),
    meta: {
      title: 'Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager',
      showSerCoName: true
    }
  },
  {
    path: '/schedules/view-projects',
    name: 'SM-ViewProjects',
    component: () => import('@/pages/SM/viewProjects/SerCoViewProjects.vue'),
    meta: {
      title: 'Downer Connect | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/update-project/:projectId',
    name: 'SM-UpdateProject',
    component: () => import('@/pages/SM/viewProjects/UpdateProjectPage.vue'),
    meta: {
      title: 'Update project | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/update-change-proposal/:proposalId',
    name: 'SM-UpdateChangeProposal',
    component: () => import('@/pages/SM/changeProposal/UpdateChangeProposalPage.vue'),
    meta: {
      title: 'Update Change Proposal | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/view-timeslots-templates',
    name: 'SM-ViewTimeslotsTemplates',
    component: () => import('@/pages/SM/viewTimeslotsTemplates/SerCoViewTimeslotsTemplates.vue'),
    meta: {
      title: 'Timeslots Templates | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/create-timeslots-template',
    name: 'SM-CreateTimeslotsTemplate',
    component: () => import('@/pages/SM/viewTimeslotsTemplates/CreateTimeslotsTemplatePage.vue'),
    meta: {
      title: 'Create Timeslots Template | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/update-timeslots-template/:templateId',
    name: 'SM-UpdateTimeslotsTemplate',
    component: () => import('@/pages/SM/viewTimeslotsTemplates/UpdateTimeslotsTemplatePage.vue'),
    meta: {
      title: 'Update Timeslots Template | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/view-schedules',
    name: 'SM-ViewSchedules',
    component: () => import('@/pages/SM/viewSchedule/SerCoViewSchedules.vue'),
    meta: {
      title: 'Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager',
      showSerCoName: true
    }
  },
  {
    path: '/schedules/view-timeslots',
    name: 'SM-ViewTimeslots',
    component: () => import('@/pages/SM/viewTimeslots/SerCoViewTimeslots.vue'),
    meta: {
      title: 'Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager',
      showSerCoName: true
    }
  },
  {
    path: '/schedules/view-rules',
    name: 'SM-ViewRules',
    component: () => import('@/pages/SM/viewRules/SerCoViewRules.vue'),
    meta: {
      title: 'View Rules | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/:proposalId/rule-change',
    name: 'SM-RuleChangeAction',
    component: () => import('@/pages/SM/changeProposal/rulesChanges/RuleChangeActionPage.vue'),
    meta: {
      title: 'Rule Change | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/:proposalId/add-timeslots',
    name: 'SM-AddTimeslots',
    component: () => import('@/pages/SM/changeProposal/timeslotsChanges/AddSetTimeslotsChangeActionPage.vue'),
    meta: {
      title: 'Add Timeslots | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/cp/:proposalId/structure-change/:changeActionId',
    name: 'SM-UpdateStructureChange',
    component: () => import('@/pages/SM/changeProposal/structureChanges/EditStructureChangeActionPage.vue'),
    meta: {
      title: 'Structure Change | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },

  {
    path: '/schedules/:proposalId/update-timeslots/:changeActionId',
    name: 'SM-UpdateTimeslots',
    component: () => import('@/pages/SM/changeProposal/timeslotsChanges/UpdateSetTimeslotsChangeActionPage.vue'),
    meta: {
      title: 'Update Timeslots | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/:proposalId/create-structure-changes',
    name: 'SM-CreateStructureChanges',
    component: () => import('@/pages/SM/changeProposal/structureChanges/CreateStructureChangesPage.vue'),
    meta: {
      title: 'Structure changes | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/:proposalId/add-baseline-capacity',
    name: 'SM-AddBaselineCapacity',
    component: () => import('@/pages/SM/changeProposal/baselineCapacity/AddBaselineCapacityPage.vue'),
    meta: {
      title: 'Add Baseline | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  },
  {
    path: '/schedules/:proposalId/update-baseline-capacity/:changeActionId',
    name: 'SM-UpdateBaselineCapacity',
    component: () => import('@/pages/SM/changeProposal/baselineCapacity/UpdateBaselineCapacityPage.vue'),
    meta: {
      title: 'Update Baseline | Schedule Manager',
      layout: 'FW',
      theme: 'SM',
      header: 'Schedule Manager'
    }
  }
]
