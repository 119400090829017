import { type RouteRecordRaw } from 'vue-router'

const basePath = '/capacity'
export const CMRoutes: Array<RouteRecordRaw> = [
  {
    path: basePath,
    name: 'CM-Home',
    component: () => import('@/pages/CM/CapacityManagerHome.vue'),
    meta: {
      title: 'Capacity manager',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/view-buckets`,
    name: 'CM-ViewBuckets',
    component: () => import('@/pages/CM/ViewBuckets.vue'),
    meta: {
      title: 'Capacity manager - View buckets',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/view-monthly-capacity`,
    name: 'CM-ViewMonthlyCapacity',
    component: () => import('@/pages/CM/ViewMonthlyCapacityPage.vue'),
    meta: {
      title: 'Capacity manager - View monthly capacity',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/update-timeslot-capacity`,
    name: 'CM-UpdateCapacity',
    component: () => import('@/pages/CM/updateTimeslotCapacity/UpdateCapacity.vue'),
    meta: {
      title: 'Capacity manager - Update capacity',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/view-next-available`,
    name: 'CM-ViewNextAvailable',
    component: () => import('@/pages/CM/ViewNextAvailableTimeslot.vue'),
    meta: {
      title: 'Capacity manager - View Next available timeslot',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/update-timeslot-availability`,
    name: 'CM-UpdateNextAvailable',
    component: () => import('@/pages/CM/updateTimeslotAvailability/UpdateTimeslotAvailability.vue'),
    meta: {
      title: 'Capacity manager - Update timeslot availability',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/update-baseline-capacity`,
    name: 'CM-UpdateBaselineCapacity',
    component: () => import('@/pages/CM/UpdateBaselineCapacity/UpdateBaselineCapacity.vue'),
    meta: {
      title: 'Capacity manager - Update baseline capacity',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/preset-filters`,
    name: 'CM-PresetFilters',
    component: () => import('@/pages/CM/admin/PresetFilters/PresetFilters.vue'),
    meta: {
      title: 'Capacity manager - Preset filters',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/update-rap`,
    name: 'CM-UpdateRAP',
    component: () => import('@/pages/CM/updateRAP/UpdateRAP.vue'),
    meta: {
      title: 'Capacity manager - Rolling activation period',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/lead-time`,
    name: 'CM-UpdateLeadTime',
    component: () => import('@/pages/CM/leadTime/UpdateLeadTime.vue'),
    meta: {
      title: 'Capacity manager - Update lead time',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/cut-off-times`,
    name: 'CM-UpdateCutOffTimes',
    component: () => import('@/pages/CM/cutOffTime/UpdateCutOffTimes.vue'),
    meta: {
      title: 'Capacity manager - Update cut-off times',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/schedule-structure`,
    name: 'CM-ViewScheduleStructure',
    component: () => import('@/pages/CM/viewScheduleStructure/viewStructure.vue'),
    meta: {
      title: 'Capacity manager - View Schedule Structure',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/history/errors`,
    name: 'CM-ViewErrorsHistory',
    component: () => import('@/pages/CM/admin/errorsHistory/ViewErrorsHistory.vue'),
    meta: {
      title: 'Capacity manager - Errors History',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  },
  {
    path: `${basePath}/history/activity`,
    name: 'CM-ViewActivityHistory',
    component: () => import('@/pages/CM/admin/activityHistory/ViewActivityHistory.vue'),
    meta: {
      title: 'Capacity manager - Activity History',
      layout: 'FW',
      header: 'Capacity Manager',
      theme: 'CM'
    }
  }
]

export function isCapacityManagerRoute (routeName: string): boolean {
  const route = CMRoutes.find((r) => r.name === routeName)
  return route !== undefined
}
