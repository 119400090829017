<template>
  <FW v-if="$route.meta.layout === 'FW'" />
  <FMP v-if="$route.meta.layout === 'FMP'" />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    FMP: defineAsyncComponent(() => import('@/layouts/FMP.vue')),
    FW: defineAsyncComponent(() => import('@/layouts/FW.vue'))
  }
})
</script>

<style lang="scss">
@import "@/assets/tailwind.css";

</style>
